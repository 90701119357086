<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="modal-form-rank">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Sellos por Rango</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group col-md-12">
              <label for="">Sitio</label>
              <v-select
                :class="[
                  $store.getters.getDarkMode ? 'dark-vselect' : '',
                  $v.form.sitio_id.$invalid ? 'is-invalid' : 'is-valid',
                ]"
                class="form-control form-control-sm p-0"
                v-model="sitio"
                placeholder="Sitio"
                label="nombre"
                :options="listasForms.sitios"
                :filterable="false"
                @search="buscarSitio"
                @input="selectSitio()"
              ></v-select>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="numero_inf">Num. de Sello Inferior</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="numero_inf"
                    placeholder="Numero Inferior"
                    v-model="form.numero_inf"
                    :class="
                      $v.form.numero_inf.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  />
                </div>
                <div class="error" v-if="!$v.form.numero_inf.required">
                  Ingrese un numero Inferior
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="numero_sup">Num. de Sello Superior</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="numero_sup"
                    placeholder="Numero Superior"
                    v-model="form.numero_sup"
                    :class="
                      $v.form.numero_sup.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  />
                </div>
                <div class="error" v-if="!$v.form.numero_sup.required">
                  Ingrese un numero Superior
                </div>
              </div>
              <div class="col-lg-12 mt-2">
                <div class="form-group">
                  <label for="tipo_empresa">Tipo Empresa</label>
                  <select
                    class="form-control form-control-sm"
                    v-model="form.tipo_empresa"
                    :class="
                      $v.form.tipo_empresa.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  >
                    <option value>Seleccione...</option>
                    <option
                      v-for="tipoEmpresa in $parent.listasForms.tipoEmpresas"
                      :key="tipoEmpresa.numeracion"
                      :value="tipoEmpresa.numeracion"
                    >
                      {{ tipoEmpresa.descripcion }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-show="!$v.form.$invalid"
              @click="saveSellos()"
            >
              Crear Sellos
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "SelloRangos",
  components: {
    vSelect,
  },
  data() {
    return {
      form: {
        sitio_id: null,
        numero_inf: null,
        numero_sup: null,
        tipo_empresa: null,
      },
      sitio: [],
      listasForms: {
        sitios: [],
      },
    };
  },
  validations: {
    form: {
      numero_inf: {
        required,
      },
      numero_sup: {
        required,
      },
      sitio_id: {
        required,
      },
      tipo_empresa: {
        required,
      },
    },
  },
  methods: {
    buscarSitio(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/sitios/lista?sitio=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.listasForms.sitios = respuesta;
            me.form.sitio_id = me.listasForms.sitios.id;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectSitio() {
      this.form.sitio_id = "";
      if (this.sitio) {
        this.form.sitio_id = this.sitio.id;
      }
    },

    buscarDif() {
      if (this.form.numero_inf > this.form.numero_sup) {
        this.$swal({
          title: "Fuera de Rango",
          text: "El numero superior no puede ser menor al numero inferior!",
          icon: "warning",
          confirmButtonText: "Aceptar",
        });
        this.form.numero_sup = null;
      }
    },

    saveSellos() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        axios({
          method: "POST",
          url: "/api/hidrocarburos/sellos",
          data: this.form,
        })
          .then((response) => {
            this.cargando = false;
            this.$refs.closeModal.click();
            this.$parent.getIndex();
            this.limpiarModal();
            this.form = {};
            this.$swal({
              icon: "success",
              title: "El rango de sellos se creo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title:
                "Ocurrio un error, por favor validar que los sellos no se encuentren duplicados...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          });
        this.cargando = false;
      }
    },

    limpiarModal() {
      this.form = {
        numero_inf: null,
        numero_sup: null,
        sitio_id: null,
        tipo_empresa: null,
      };
      this.sitio = [];
    },
  },
};
</script>
